import materials from '../materials.json';

const Materials = ({setSelectedPalette}) => {
    const listMaterials = () => {
        return materials.map(material => (
            <div className="material" onClick={() => setSelectedPalette(material.colors)}>
                <h3>{material.grammage}</h3>
            </div>
        ))
    }

    return (
        <div className="materials_container full_width">
            <h2>Wybierz materiał</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor explicabo neque nesciunt dicta iure veniam tempora assumenda facilis sint alias, in unde at fugit quam doloremque tenetur praesentium voluptate aspernatur!</p>
            <div className="materials_list">
                {listMaterials()}
            </div>
        </div>
    )
}

export default Materials;