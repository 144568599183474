const Info = () => {
    return (
        <div className="info_container full_width">
            <h2>Dodatkowe informacje</h2>
            Imię i nazwisko: <input type="text" />
            Nazwa firmy: <input type="text" />
            Adres email: <input type="text" />
            Nr telefonu: <input type="text" />
            Uwagi: <textarea name="" id="" cols="30" rows="10"></textarea>
        </div>
    )
}

export default Info;