import models from '../models.json';

const Models = ({setSelectedWidth, setSelectedLength, setSelectedType, setDoubleEars}) => {
    const listModels = () => {
        return models.map(model => (
            <div class="model" onClick={() => selectModel(model.width, model.length, model.type, model.doubleEars)}>
                <img src={model.image} alt={model.name} />
                <h3>{model.name}</h3>
                <p>{model.desc}</p>
                <p>Szerokość: {model.width}</p>
                <p>Wysokość: {model.length}</p>
            </div>  
        ));
    };

    const selectModel = (width, length, type, doubleEars) => {
        setSelectedWidth(width);
        setSelectedLength(length);
        setSelectedType(type);
        setDoubleEars(doubleEars);
    }

    return (
        <div className="models_container full_width">
            <h2>Wybierz model</h2>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusantium officiis dolores quis fugiat sed porro et quod, saepe explicabo assumenda cupiditate temporibus labore iure ipsa quaerat illo ratione culpa blanditiis?</p>
            <div className="models_list">
                {listModels()}
            </div>
        </div>
    )
}

export default Models;