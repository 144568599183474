import { useState } from "react"

const Accessories = ({selectedType, doubleEars, pocketWidth, setPocketWidth, pocketHeight, setPocketHeight}) => {
    const [isCustomPocketSize, setIsCustomPocketSize] = useState();

    const checkDoubleEarsIsExist = () => {
        if (doubleEars) {
            return (
                <>
                    <h3>Rozmiar uszy dla torby z dwoma uszami</h3>
                    <select name="ears-size" id="ears-size">
                        <option value="25">2,5cm</option>
                        <option value="30">3cm</option>
                        <option value="40">4cm</option>
                    </select>
                    <h3>Krzyżyki</h3>   
                    <select name="crosses-option" id="crosses-option">
                        <option value="krzyżyki">Tak</option>
                        <option value="bez krzyżyków">Nie</option>
                    </select>
                </>
            )
        }
    }

    const checkSelectedTypeIsSack = () => {
        if (selectedType === 'sack') {
            return (
                <>
                    <h2>Personalizacja sznurka</h2>
                    <h3>Rodzaj sznurka</h3>
                    <select name="tape-option" id="tape-option">
                        <option value="poliester">Poliester</option>
                        <option value="bawełna">Bawełna</option>
                    </select>
                    <h3>Kolor sznurka</h3>
                    {/* Wybór kolorów */}
                </>
            )
        }
    }

    const customPocketSize = () => {
        if (isCustomPocketSize) {
            return (
                <>
                    <p>Szerokość: {pocketWidth} [mm]</p>
                    <input type="range" onChange={changeWidth} />
                    <p>Wyokość: {pocketHeight} [mm]</p>
                    <input type="range" onChange={changeHeight} />
                </>
            )
        }
    }
    
    const changeWidth = e => {
        setPocketWidth(e.target.value);
    }

    const changeHeight = e => {
        setPocketHeight(e.target.value);
    }
    
    const toggleCustomPocketSize = e => {
        setIsCustomPocketSize(e.target.checked);
    }

    return (
        <div className="accessories_container full_width">
            <h2>Personalizacja uszy</h2>
            {checkDoubleEarsIsExist()}
            <h3>Kolor uszy</h3>
            <input type="checkbox" id="custom-ears-color" name="custom-ears-color" checked />
            <label htmlFor="custom-ears-color">Taki sam jak kolor torby</label>
            {/* Wybór kolorów */}
            <h3>Materiał wykonania</h3>
            <select name="ears-material" id="ears-material">
                <option value="podstawowy">Podstawowy</option>
                <option value="taśma bawełniana">Taśma bawełniana</option>
                <option value="taśma drukowana">Taśma drukowana (na zamówienie)</option>
            </select>
            <h2>Rodzaj zamknięcia</h2>
            <select name="close-option" id="close-option">
                <option value="napa">Napa</option>
                <option value="rzep">Rzep</option>
                <option value="suwak">Suwak</option>
                <option value="zamknięcie magnetyczne">Zamknięcie magnetyczne</option>
            </select>
            <h2>Personalizacja kieszonki</h2>
            <h3>Rozmiar kieszonki</h3>
            <input type="checkbox" id="pocket-size" name="pocket-size" onChange={toggleCustomPocketSize} />
            <label htmlFor="pocket-size">Własny rozmiar kieszonki</label>
            {customPocketSize()}
            <h3>Zapięcie kieszonki</h3>
            <select name="pocket-option" id="pocket-option">
                <option value="otwarta">Bez zapięcia</option>
                <option value="napa">Napa</option>
                <option value="suwak">Suwak</option>
            </select>
            {checkSelectedTypeIsSack()}
        </div>
    )
}

export default Accessories;