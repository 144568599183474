const Colors = ({selectedPalette, setSelectedColor}) => {

    const listColors = () => {
        if (selectedPalette) {
            return selectedPalette.map(color => (
                <div className="color" style={{background: color}} onClick={() => setSelectedColor(color)}>
                </div>
            ))
        }
    }

    return (
        <div className="colors_containers full_width">
            <h2>Wybierz kolor</h2>
            <div className="colors_wrapper">
                {listColors()}
            </div>
            <input type="checkbox" id="custom-color" name="custom-color" />
            <label htmlFor="custom-color">Zdefiniuj własny kolor</label>
        </div>
    )    
}

export default Colors;