import './App.css';
import Models from './components/Models';
import Sizes from './components/Sizes';
import Materials from './components/Materials';
import Colors from './components/Colors';
import Accessories from './components/Accessories';
import Logo from './components/Logo';
import Info from './components/Info';
import Summary from './components/Summary';
import SendForm from './components/SendForm';

import { useState } from 'react';

function App() {
  const [selectedWidth, setSelectedWidth] = useState();
  const [selectedLength, setSelectedLength] = useState();
  const [selectedType, setSelectedType] = useState();
  const [doubleEars, setDoubleEars] = useState();

  const [selectedPalette, setSelectedPalette] = useState();
  const [selectedColor, setSelectedColor] = useState();

  const [pocketWidth, setPocketWidth] = useState();
  const [pocketHeight, setPocketHeight] = useState();

  return (
    <div className="App">
      <h2>Torby bawełniane - Konfigurator v0.1</h2>
      <Models 
        setSelectedWidth={setSelectedWidth} 
        setSelectedLength={setSelectedLength} 
        setSelectedType={setSelectedType}
        setDoubleEars={setDoubleEars} 
      />
      <Sizes selectedWidth={selectedWidth} selectedLength={selectedLength} />
      <Materials setSelectedPalette={setSelectedPalette} />
      <Colors selectedPalette={selectedPalette} setSelectedColor={setSelectedColor} />
      <Accessories 
        selectedType={selectedType} 
        doubleEars={doubleEars} 
        pocketWidth={pocketWidth}
        setPocketWidth={setPocketWidth}
        pocketHeight={pocketHeight}
        setPocketHeight={setPocketHeight}
      />
      <Logo />
      <Info />
      <Summary />
      <SendForm />
    </div>
  );
}

export default App;
