import { useState } from 'react';

const Sizes = ({selectedWidth, selectedLength}) => {
    const [width, setWidth] = useState();
    const [length, setLength] = useState();

    const changeWidth = e => {
        setWidth(e.target.value);
    }

    const changeLength = e => {
        setLength(e.target.value);
    }

    return (
        <div className="sizes-container full_width">
            <h2>Wybierz rozmiar</h2>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore vitae consectetur nemo aut nam, ratione voluptatum voluptates totam ex amet suscipit vero facere quam pariatur odio, tenetur, laborum accusamus. Quod.</p>
            <p>Szerokość: {width ? width : selectedWidth}</p>
            <input type="range" onChange={changeWidth} />
            <p>Wysokość: {length ? length : selectedLength}</p>
            <input type="range" onChange={changeLength} />
        </div>
    )
}

export default Sizes;