const Logo = () => {
    return (
        <div className="logo_container full_width">
            <h2>Dodaj swoje logo</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi ea sed porro explicabo, ipsum quam placeat. Voluptatibus quaerat laudantium, mollitia pariatur impedit voluptatem molestiae vero. Dolores qui sequi laboriosam sint.</p>
            <input type="file" accept="image/png, image/jpeg" />
        </div>
    );
}

export default Logo;